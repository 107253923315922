import React, { useState, useContext, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import Swipe from "react-swipeable-views"

import { IProduct, IOgImage } from "../graph"
import { Product as StructuredProduct, ItemAvailabilityEnum, WithContext } from "schema-dts"
import Layout from "../layout"
import { CartContext } from "../components/cart"
import { LangContext, Money, Message } from "../components/l10n"
import CartIcon from "../images/cart"
import RemoveIcon from "../images/remove"
import AddIcon from "../images/add"
import SquareImage from "../components/squareimage"
import { isoDate } from "../helpers/date"

const FB_SHARE = "https://www.facebook.com/plugins/share_button.php?layout=button&size=large&width=120&height=30"

const inOneMonth = isoDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000))

const Product = styled.section`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 599px) {
    display: block;
    margin-top: 60px;
  }
  @media (max-width: 399px) {
    margin-top: 40px;
  }
`
const Photos = styled.div`
  margin: 30px;
  @media (min-width: 500px) {
    width: 450px;
  }
  @media (max-width: 499px) {
    margin: 30px 15px;
  }
`
const Thumbs = styled.div`
  display: flex;
  @media (max-width: 499px) {
    display: none;
  }
`
const Dots = styled.div`
  text-align: center;
  margin-top: 10px;
  @media (min-width: 500px) {
    display: none;
  }
`
const Dot = styled.button<{ active: boolean }>`
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 5px;
  background: ${(props) => (props.active ? "black" : "#979797")};
`

const PhotoButton = styled.button`
  margin: 20px 20px 0 0;
`
const Content = styled.div`
  flex: 1;
  margin: 0 30px 40px;
  @media (max-width: 499px) {
    margin: 0 15px 40px;
  }
`
const Title = styled.h1`
  font-size: 2rem;
  margin: 18px 0 10px -2px;
  @media (max-width: 499px) {
    font-size: 1.5rem;
  }
`
const Price = styled.div`
  font-size: 20px;
  margin: 0 0 20px;
`
const Subtitle = styled.div`
  color: #9b9b9b;
  font-size: 18px;
  margin-bottom: 14px;
`
const Quantity = styled.div`
  display: inline-block;
  border: 1px solid #d8d8d8;
  padding: 0 4px;
  margin: 10px 40px 10px 0;
  button {
    padding: 8px;
  }
  span {
    margin: 0 10px;
  }
  @media (max-width: 499px) {
    margin-right: 30px;
  }
`
const CartButton = styled.button<{ added: boolean }>`
  border: 1px solid #d8d8d8;
  padding: 8px 16px;
  margin: 10px 0;
  :hover {
    border-color: black;
  }
  span {
    margin-right: 10px;
    ${(props) => (props.added ? "color: white;" : "")}
  }
  svg {
    ${(props) => (props.added ? "fill: white;" : "")}
  }
  ${(props) => (props.added ? "background: black; border-color: black;" : "")}
`
const Description = styled.div`
  white-space: pre-line;
  margin: 20px 0 30px;
  line-height: 23px;
`

const ProductTemplate = ({ data: { products, site } }) => {
  const { add } = useContext(CartContext)
  const { lang } = useContext(LangContext)

  const product: IProduct = products.edges.find(({ node }) => node.node_locale.indexOf(lang) === 0).node
  const { slug, photos, thumbs, name, subtitle, price } = product

  const htmlDescription = product.description && product.description.childContentfulRichText.html
  const description = htmlDescription && htmlDescription.replace(/<[^>]+>/g, " ").trim() // strip tags

  const { title, siteUrl } = site.siteMetadata

  const [photoIndex, setPhoto] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [added, setAdded] = useState(false)
  const timeout = useRef<number>()

  const handleIndex = (index: number) => {
    setPhoto(index)
  }
  const handlePhoto = (index: number) => () => {
    setPhoto(index)
  }

  const handleSub = () => {
    setQuantity(Math.max(1, quantity - 1))
  }
  const handleAdd = () => {
    setQuantity(quantity + 1)
  }

  const handleCart = () => {
    add(slug, quantity)
    setAdded(true)
    // setQuantity(1)
    timeout.current = setTimeout(() => {
      setAdded(false)
    }, 3000)
  }

  useEffect(
    () => () => {
      clearTimeout(timeout.current)
    },
    []
  )

  const url = `${siteUrl}/product/${slug}/`

  const image: IOgImage = {
    src: "https:" + photos[0].fluid.src,
    width: 450,
    height: Math.round(450 / photos[0].fluid.aspectRatio),
  }

  const structured: WithContext<StructuredProduct> = {
    "@context": "https://schema.org",
    "@type": "Product",
    name,
    description,
    brand: {
      "@type": "Brand",
      name: title,
    },
    image: photos.map(({ fluid }) => "https:" + fluid.src),
    offers: {
      "@type": "Offer",
      url,
      priceCurrency: "EUR",
      price,
      availability: ItemAvailabilityEnum.InStock,
      priceValidUntil: inOneMonth,
    },
    mpn: slug,
    sku: slug,
  }

  return (
    <Layout title={name} image={image} cart>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }} />
      <Product>
        <Photos>
          <Swipe index={photoIndex} onChangeIndex={handleIndex} resistance>
            {photos.map(({ fluid }, index) => (
              <SquareImage key={index} fluid={fluid} />
            ))}
          </Swipe>
          {photos.length > 1 && (
            <>
              <Thumbs>
                {thumbs.map(({ fixed }, index) => (
                  <PhotoButton key={index} onClick={handlePhoto(index)}>
                    <Image fixed={fixed} />
                  </PhotoButton>
                ))}
              </Thumbs>
              <Dots>
                {photos.map((_, index) => (
                  <Dot key={index} onClick={handlePhoto(index)} active={photoIndex === index} />
                ))}
              </Dots>
            </>
          )}
        </Photos>
        <Content>
          <Title>{name}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Price>
            <Money amount={price} />
          </Price>
          <div>
            <Quantity>
              <button onClick={handleSub}>
                <RemoveIcon />
              </button>
              <span>{quantity}</span>
              <button onClick={handleAdd}>
                <AddIcon />
              </button>
            </Quantity>
            <CartButton onClick={handleCart} added={added} disabled={added}>
              <Message id={added ? "added" : "add"} Tag="span" />
              <CartIcon />
            </CartButton>
          </div>
          <Description dangerouslySetInnerHTML={htmlDescription ? { __html: htmlDescription } : null} />
          <iframe
            src={`${FB_SHARE}&href=${encodeURIComponent(url)}`}
            width={120}
            height={30}
            scrolling="no"
            frameBorder={0}
            allow="encrypted-media"
          />
        </Content>
      </Product>
    </Layout>
  )
}

export const query = graphql`
  query Product($slug: String!) {
    products: allContentfulProduct(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          node_locale
          name
          subtitle
          slug
          order
          price
          photos {
            fluid(maxWidth: 450, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          thumbs: photos {
            fixed(width: 65, quality: 90) {
              ...GatsbyContentfulFixed
            }
          }
          description {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default ProductTemplate
